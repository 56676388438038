import React from 'react';
import PropTypes from 'prop-types';
import { SEO } from '../components';
import Main from '../page-components/legal/Cookie';

const Cookies = ({ location: { pathname } }) => {
  //

  return (
    <article style={{ minHeight: '70vh' }}>
      <SEO title="Cookie Policy" description="Cookie Policy" />
      <Main />
    </article>
  );
};

export default Cookies;

Cookies.propTypes = {
  location: PropTypes.object.isRequired,
};
