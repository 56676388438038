import React from 'react';
import { Link } from 'gatsby';
import { Container, Block, MarkdownStyles } from '../../components';

const Cookie = () => {
  //
  return (
    <section>
      <Container>
        <Block>
          <div style={{ maxWidth: '700px', margin: '0 auto' }}>
            <MarkdownStyles>
              <h1>Cookie Policy</h1>
              <p>This website stores cookies on your computer.</p>
              <p>
                These cookies are used to collect information about how you interact with our website and allow us to
                remember you.
              </p>
              <p>
                We use this information in order to improve and customize your browsing experience and for analytics and
                metrics about our visitors both on this website and other media.
              </p>
              <p>
                To find out more about the cookies we use, see our <Link to="/privacy">Privacy Policy</Link>.
              </p>
            </MarkdownStyles>
          </div>
        </Block>
      </Container>
    </section>
  );
};

export default Cookie;
